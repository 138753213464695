<template>
  <div>

    <animal-mating-add-new
      :is-add-new-goat-sidebar-active.sync="isAddNewGoatSidebarActive"
      @refetch-data="refetchData"
    />
    
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

        <!-- Table Top -->
        <b-row class="pt-2 pl-2 pr-2">  
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h4>List kambing dalam fase kawin</h4>
          </b-col>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            
            <b-button
                variant="primary"
                @click="isAddNewGoatSidebarActive = true"
              >
                <span class="text-nowrap">+ Tambah Data</span>
              </b-button>
          </b-col>
        </b-row>
        <hr/>
        <b-row class="pl-2 pr-2 pb-2">
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
          <b-form-input
          v-model="searchQuery"
          class="d-inline-block mr-1"
          placeholder="Search..."
        />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              
            </div>
          </b-col>
        </b-row>

      

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchGoats"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(jantan)="data">
          <span>{{ data.item.jantan ? data.item.jantan : data.item.source_male }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          
          <feather-icon icon="EditIcon"  @click="setSelectedMating(data.item.id, data.item.jantan ? data.item.jantan : data.item.source_male, data.item.betina); showModalUpdateStatus = !showModalUpdateStatus"/>
          
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalGoats"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
      v-model="showModalUpdateStatus"
      id="modal-update-status-mating"
      title="Perbarui Hasil Perkawinan"
      centered
    >
      <b-form>
        <b-form-group label="Induk Jantan" label-for="male">
          <b-form-input
            id="male"
            v-model="selectedMating.male_animal"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group label="Induk Betina" label-for="female">
          <b-form-input
            id="female"
            v-model="selectedMating.female_animal"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group
          label="Hasil Perkawinan"
          label-for="mating-result"
        >
          <v-select
            id="mating-result"
            v-model="selectedResultMating"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="resultOptions"
          />
        </b-form-group>

        <b-form-group
          :class="selectedResultMating.value == 'positive' ? 'd-block' : 'd-none'"
          label="Hari Perkiraan Lahir"
          label-for="birth_date_estimation"
        >
          <b-form-datepicker
            id="birth_date_estimation"
            placeholder="Pilih tanggal perkiraan lahir"
            v-model="selectedMating.birth_date_estimation"
            trim
          />
        </b-form-group>

        <b-form-group
          label="Tanggal Perkawinan Selanjutnya"
          label-for="next_mating_date"
        >
          <b-form-datepicker
            id="next_mating_date"
            placeholder="Pilih tanggal perkiraan lahir"
            v-model="selectedMating.next_mating_date"
            trim
          />
        </b-form-group>
        
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button class="float-right ml-1 mr-1" size="md" variant="secondary" @click="onCancelModal()">
            Batal
          </b-button>
          <b-button 
            class="float-right" 
            size="md" 
            variant="primary" 
            @click="onSubmitModal()"
            v-if="$can('update', 'animal_mating')"
            >
            Simpan
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  VBModal,
  BForm,
  BFormGroup,
  BFormDatepicker,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useAnimalMatingsList from './useAnimalMatingsList'
import animalMatingStoreModule from './animalMatingStoreModule'
import AnimalMatingAddNew from './AnimalMatingAddNew.vue'

import axios from "@axios"

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    AnimalMatingAddNew,

    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    BFormGroup,
    BFormDatepicker,

    vSelect,
    VBModal,
  },
  directives: {
    'b-modal': VBModal,
  },
   data() {
    return {
      resultOptions: [
        { label: 'Positive', value: 'positive'},
        { label: 'Negative', value: 'negative'},
      ],
    }
  },
  setup() {
    const toast = useToast()

    const GOAT_APP_STORE_MODULE_NAME = 'app-goat'

    // Register module
    if (!store.hasModule(GOAT_APP_STORE_MODULE_NAME)) store.registerModule(GOAT_APP_STORE_MODULE_NAME, animalMatingStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(GOAT_APP_STORE_MODULE_NAME)) store.unregisterModule(GOAT_APP_STORE_MODULE_NAME)
    })

    const {
      fetchGoats,
      tableColumns,
      perPage,
      currentPage,
      totalGoats,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Extra Filters
      goatsParentList,
      
    } = useAnimalMatingsList()


    const blankSelectedMating = {
      id: null,
      male_animal: null,
      female_animal: null,
      birth_date_estimation: null,
      next_mating_date: null,
      result: null,
    }

    const selectedResultMating= ref('');

    // set birthDateEstimation to 5 month after current day
    const dateEstimation = new Date(); // current day
    dateEstimation.setDate(dateEstimation.getDate() + 150); // current day + 150 day (which is 5 month)
    blankSelectedMating.birth_date_estimation = dateEstimation;
    
    
    const isAddNewGoatSidebarActive = ref(false);

    const showModalUpdateStatus = ref(false);
    const selectedMating = ref(JSON.parse(JSON.stringify(blankSelectedMating)))

    // when click button edit
    const setSelectedMating = (id, jantan, betina) => {
      selectedMating.value.id = id
      selectedMating.value.male_animal = jantan
      selectedMating.value.female_animal = betina
    }

    // when click simpan status
    const onSubmitModal = () => {
        selectedMating.value.result = selectedResultMating.value.value  

        store.dispatch('app-goat/updateStatus', 
        selectedMating.value,
        
        )
        
        .then(() => {
          // reset selectedMating
          selectedMating.value = JSON.parse(JSON.stringify(blankSelectedMating))
          // close modal
          showModalUpdateStatus.value = !showModalUpdateStatus.value
          // show toast
          toast({
            component: ToastificationContent,
            props: {
              title: 'Hasil Perkawinan Berhasil Diperbarui',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          // // refetch data
          refetchData()
        })
        
    }

    const onCancelModal = () => {
        // check status 
        selectedResultMating.value = JSON.parse(JSON.stringify(blankSelectedMating));
        showModalUpdateStatus.value = !showModalUpdateStatus.value;
    }

    return  {
      // Sidebar
      isAddNewGoatSidebarActive,
      // modal
      showModalUpdateStatus,
      onSubmitModal,
      onCancelModal,
      selectedResultMating,
      setSelectedMating,
      selectedMating,

      fetchGoats,
      tableColumns,
      perPage,
      currentPage,
      totalGoats,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      goatsParentList,
      selectedMating,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
