<template>
  <b-sidebar
    id="add-new-goat-sidebar"
    :visible="isAddNewGoatSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-goat-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Catat perkawinan kambing
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

        <validation-provider
        #default="validationContext"
        name="Jenis Perkawinan?"
        rules="required"
      >
        <b-form-group label="Jenis Perkawinan?">
            <b-form-radio-group
              v-model="selectedisNatural"
              :options="isNatural"
              class="demo-inline-spacing mb-1"
              value-field="value"
              text-field="text"
              disabled-field="disabled"
            />
          </b-form-group>

          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
      </validation-provider>
    
      <validation-provider
          #default="validationContext"
          name="Jenis Suntikan"
          :rules="selectedisNatural == 'buatan' ? required : ''"
        >
          <b-form-group :class="selectedisNatural == 'buatan' ? 'd-block' : 'd-none'"
            label="Jenis Suntikan"
            label-for="source_male"
          >
            <b-form-input
              id="source_male"
              v-model="goatData.source_male"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Jenis Suntikan"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Goat Mother -->
        <validation-provider
        #default="validationContext"
        name="Induk Kambing Betina"
        rules="required"
      >
        <b-form-group
          label="Induk Kambing Betina"
          label-for="f_animal_id"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="selectedData.f_animal_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="parentFemaleOptions"
            :clearable="true"
            input-id="f_animal_id"
            placeholder="Pilih Induk Betina"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-form-checkbox
        id="checkbox-1"
        class="mb-1"
        v-model="fromOutsideIsChecked"
        name="checkbox-1"
        :value="!fromOutsideIsChecked"
        :unchecked-value="!fromOutsideIsChecked"
      >
        Pejantan dari peternakan lain
      </b-form-checkbox>

        <!-- Goat Father -->
      <div :class="selectedisNatural == 'buatan' || fromOutsideIsChecked == true ? 'd-none' : 'd-block'">
        <validation-provider
        #default="validationContext"
        name="Induk Kambing Jantan"
        :rules="selectedisNatural == 'alami' && !fromOutsideIsChecked ? 'required' : ''"
      >
        <b-form-group
          label="Induk Kambing Jantan"
          label-for="m_animal_id"
          :state="getValidationState(validationContext)"
          :readonly="selectedisNatural == 'alami' ? false : true"
        >
          <v-select
            v-model="selectedData.m_animal_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="parentMaleOptions"
            :clearable="true"
            input-id="m_animal_id"
            placeholder="Pilih Induk Jantan"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </div>

    <validation-provider
        #default="validationContext"
        name="Nama Peternakan"
        :rules="fromOutsideIsChecked ? required : ''"
      >
        <b-form-group :class="fromOutsideIsChecked === true ? 'd-block' : 'd-none'"
          label="Nama Peternakan"
          label-for="source_male"
        >
          <b-form-input
            id="source_male"
            v-model="goatData.source_male"
            autofocus
            :state="getValidationState(validationContext)"
            trim
            placeholder="Nama Peternakan"
          />

          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

         
          <!-- Birth Place -->
          <validation-provider
            #default="validationContext"
            name="Place"
            rules="required"
          >
            <b-form-group
              label="Place"
              label-for="place"
            >
              <b-form-input
                id="place"
                v-model="goatData.place"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Tempat Perkawinan"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Birth Date -->
          <validation-provider
            #default="validationContext"
            name="Date"
            rules="required"
          >
            <b-form-group
              label="Date"
              label-for="mating_date"
            >
              <b-form-datepicker
                id="mating_date"
                placeholder="Choose a date"
                v-model="goatData.mating_date"
                :state="getValidationState(validationContext)"
                trim
              />
              
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

      
          <!-- Form Actions -->
          <div class="d-flex mt-2">

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isAddButtonClick"
              v-if="$can('create', 'animal_mating')"
            >
              <span v-if="!isAddButtonClick">Tambah</span>
              <b-spinner small v-if="isAddButtonClick" />
              <span class="sr-only" v-if="isAddButtonClick">Loading...</span>
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Batal
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { BSpinner, BSidebar, BForm, BFormGroup, BFormInput,BFormRadioGroup,BFormCheckbox, BFormInvalidFeedback, BButton, BFormDatepicker } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormInvalidFeedback,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormCheckbox,
    BSpinner,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewGoatSidebarActive',
    event: 'update:is-add-new-goat-sidebar-active',
  },
  props: {
    isAddNewGoatSidebarActive: {
      type: Boolean,
      required: true,
    },
   
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      gender: [
        'Male',
        'Female',
      ],
      isNatural: [
        { text: 'Alami', value: 'alami', disabled: false },
        { text: 'Suntik', value: 'buatan', disabled: false },
      ],
      penOptions: [],
      deviceOptions: [],
      parentMaleOptions: [],
      parentFemaleOptions: [],
    }
  },
  setup(props, { emit }) {
    const isAddButtonClick = ref(false)
    // use Toast
    const toast = useToast()
    // setup json post goat data
    const blankGoatData = {
      f_animal_id: null,
      m_animal_id: null,
      place: 'SMKN 1 Tulung Klaten',
      source_male: '',
      mating_date: null,
      // start static data -- untuk sementara ngejar target
      result: 'process',
      pen_id: null,
      // finish static data
    }

    const blankOptionSelected = {
       f_animal_id: null,
       m_animal_id: null,
       pen_id: null,
    }

    const selectedData = ref(JSON.parse(JSON.stringify(blankOptionSelected)));

    //set default birth date is today
    const today = new Date();
    blankGoatData.mating_date = today;

    // set default selected have parents to have
    const selectedisNatural = ref('alami');
    const fromOutsideIsChecked = ref(false);
    
    // define goatData from JSON data to ref variable
    const goatData = ref(JSON.parse(JSON.stringify(blankGoatData)))

    // when click Cancle button
    const resetGoatData = () => {
      goatData.value = JSON.parse(JSON.stringify(blankGoatData))
    }

    // when click Add button
    const onSubmit = async () => {
      isAddButtonClick.value = !isAddButtonClick.value

      if(selectedisNatural.value == 'alami' && !fromOutsideIsChecked.value){
          goatData.value.m_animal_id = selectedData.value.m_animal_id.value;
      }
        
      goatData.value.f_animal_id = selectedData.value.f_animal_id.value;

      goatData.value.pen_id = 5;
      // goatData.value.pen_id = selectedData.value.pen_id.value;
      await store.dispatch('app-goat/addMating', goatData.value)
      .then((response) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Successfully Record new Mating',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        emit('refetch-data')
        emit('update:is-add-new-goat-sidebar-active', false)
        isAddButtonClick.value = !isAddButtonClick.value
      }).catch((response) => {
        isAddButtonClick.value = !isAddButtonClick.value
        toast({
          component: ToastificationContent,
          props: {
            title: 'Cek kembali isian form!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

      
      })
    }
  

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetGoatData)

    return {
      goatData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      selectedisNatural,
      selectedData,
      fromOutsideIsChecked,
      isAddButtonClick,
    }
  },
  async mounted() {

    // get available 
    await this.$http.get('/animals/male').then(response => {
      const maleAnimals = response.data.data

      maleAnimals.forEach(male => {
        this.parentMaleOptions.push(
          {
            label: male.name, 
            value:  male.id
          }
        )
      });
    })

    await this.$http.get('/animals/female').then(response => {
      const maleAnimals = response.data.data

      maleAnimals.forEach(female => {
        this.parentFemaleOptions.push(
          {
            label: female.name, 
            value:  female.id
          }
        )
      });
    })

    await this.$http.get('/pens/available').then(response => {
      const availablePens = response.data.data

      availablePens.forEach(pen => {
        this.penOptions.push(
          {
            label: pen.name, 
            value:  pen.id
          }
        )
      });
    })

    // get available device
    await this.$http.get('/devices').then(response => {
      const availableDevices = response.data.data

      availableDevices.forEach(device => {
        this.deviceOptions.push(
          {
            label: device.name, 
            value:  device.id
          }
        )
      });
    })

    // get available 
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-goat-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
