import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useGoatsList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'jantan', sortable: true },
    { key: 'betina', sortable: true },
    { key: 'kandang', sortable: true },
    { key: 'tanggal', sortable: true },
    { key: 'hasil', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalGoats = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const goatsParentList = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalGoats.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery,], () => {
    refetchData()
  })

  const fetchGoats = (ctx, callback) => {
    store
      .dispatch('app-goat/fetchGoats', {
        result: 'process'
      })
      .then(response => {
        const data = response.data.data
        const { total } = response.data.data

        const parentOptions = []

        data.forEach(goat => {
          parentOptions.push(
            {
              label: goat.name, 
              value:  goat.id
            }
          )
        });
        
        callback(data)
        totalGoats.value = total
        goatsParentList.value = parentOptions
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching goats list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchGoats,
    tableColumns,
    perPage,
    currentPage,
    totalGoats,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    refetchData,

    goatsParentList,
  }
}
